// palette for Crazy Winners Casino
$bg-color: #350b24;
$text-color: #fff3f7;
$primary: #fcd744;
$primary: #ffc50d;
$success: #44FC80;
$info: #7DB1B8;
$danger: #FC4844;


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;



.sticky-sidebar {
  .nav-link:hover {
    color: $primary !important;
    .casino-icon path {
      fill: $primary !important;
    }
  }
  .active-link {
    color: $primary !important;
    .casino-icon {
      opacity: 1;
      path {
        fill: $primary !important;
      }
    }
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}


#promos {
  img {
    width: 130% !important;
  }
}

